import { memo, useState, useCallback } from 'react';
import type React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Truncate from 'react-truncate';
import ConditionalWrapper from '@/storychief/components/ConditionalWrapper';

type TruncateWithTooltipProps = {
  text: React.ReactNode;
  lines?: number;
  delayShow?: number;
};

const TruncateWithTooltip = memo(
  ({ text, lines = 1, delayShow = 100 }: TruncateWithTooltipProps): React.JSX.Element => {
    // Variables
    const [truncated, setTruncated] = useState(false);

    // Functions
    const handleTruncate = useCallback(
      (truncate) => {
        if (truncate !== truncated) {
          setTruncated(truncate);
        }
      },
      [truncated],
    );

    const wrapper = useCallback(
      (children) => (
        <OverlayTrigger
          placement="top"
          delayShow={delayShow}
          overlay={<Tooltip id="truncate-with-tooltip">{text}</Tooltip>}
        >
          <div>{children}</div>
        </OverlayTrigger>
      ),
      [delayShow, text],
    );

    // Render
    return (
      <ConditionalWrapper condition={truncated} wrapper={wrapper}>
        <Truncate onTruncate={handleTruncate} lines={lines}>
          {text}
        </Truncate>
      </ConditionalWrapper>
    );
  },
);

export default TruncateWithTooltip;
